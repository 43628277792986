html {
    font-family: "Montserrat", sans-serif;
    font-size: 1rem;
}

body {
    width: 100%;
    overflow-x: hidden;
    -webkit-text-size-adjust: 100%;
}

a {
    text-decoration: none;
    color: inherit;
    font-family: inherit;
    font-size: inherit;
}

p {
    font-family: "Montserrat", sans-serif;
    font-weight: 400;
    font-size: 1rem;
}

h1 {
    font-family: "Lora", serif;
    font-weight: 400;
}

h2 {
    font-family: "Lora", serif;
    font-weight: 400;
}

h3 {
    font-family: "Lora", serif;
    font-weight: 400;
}

h4 {
    font-family: "Lora", serif;
    font-weight: 400;
}

h5 {
    font-family: "Montserrat", sans-serif;
    font-weight: 400;
}

h6 {
    font-family: "Montserrat", sans-serif;
    font-weight: 400;
}

:root {
    --primary-color: #ffe845;
    --primary-color-shade: #ffe100;
    --secondary-color: #442da7;
    --secondary-color-shade: #634aca;
    --off-white: #faf9f6;
    --off-white-shade: #faf9f6dc;
    --link-blue: #6abff8;

}